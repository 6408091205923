import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import WistiaPopoverVideo from '../../../components/WistiaPopoverVideo';
import FreeTrialSignup from '../../../components/FreeTrialSignup';

import './style.scss';

const MarketingPlan = () => {
    return (
        <Layout navTheme="light">
            <Seo
                title="Bulk action emailing | GlassHive"
                description="Easily reach out to many contacts and increase sales activities with GlassHive’s bulk action emailing feature. Check it out!"
            />
            <div className="bulk-action-page">
                <Container className="container-1">
                    <Row className="center">
                        <Col className="centered-text">
                            <h1 className="white-text max-width-1100">
                                Follow up with all your leads in a few clicks
                            </h1>
                            <p className="hero-subtext white-text">
                                Save time and gain efficiency with bulk action
                                emailing
                            </p>
                        </Col>
                    </Row>
                    <img
                        className="white-divider"
                        src={require('../../../images/features/lead-scrubbing/white-divider.svg')}
                        alt="white divider"
                    />
                </Container>
                <Container className="container-2">
                    <Row>
                        <Col className="three-fifth video-column">
                            <WistiaPopoverVideo id={'c65qjly25o'}>
                                <img
                                    className="thumbnail"
                                    src={require('../../../images/features/bulk-actions/thumbnail.png')}
                                    alt="Bulk actions video thumbnail"
                                />
                            </WistiaPopoverVideo>
                        </Col>
                        <Col className="two-fifth">
                            <h4 className="centered-mobile">
                                All-in-one mass email functionality
                            </h4>
                            <p>
                                Easily send out emails to multiple contacts at
                                one time.
                            </p>
                            <p>
                                This feature allows you or your sales team to
                                increase their sales touches and improve their
                                overall sales activities numbers. Imagine being
                                able to follow up with multiple leads with 3
                                clicks allowing you and your team to conduct
                                follow ups quickly and efficiently.
                            </p>
                            <p>
                                Learn more about how simple it is to reach your
                                contacts by watching our video!
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-3">
                    <Row>
                        <Col className="two-fifth">
                            <h4 className="centered-mobile">
                                Mass email sending
                            </h4>
                            <p>
                                Reach out to multiple contacts at one time based
                                on various filters.
                            </p>
                            <h4 className="centered-mobile">Tailored emails</h4>
                            <p>
                                Customize messages for certain contacts and
                                increase sales velocity.
                            </p>
                        </Col>
                        <Col className="three-fifth form-column">
                            <FreeTrialSignup titleText="Try GlassHive with a zero cost subscription!" />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default MarketingPlan;
